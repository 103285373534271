import React from 'react';
import { Link } from 'gatsby';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../../helper';
import SectionMedia from '../../LayoutComponents/SectionMedia';
import Image from '../../../../Image';
import findCategoryById from '../../../../../helpers/findCategoryById';

import * as styles from './styles.module.css';

const CategoryLayout = ({ section, themeData, articleMother, categoryPathList, images, isFirst }) => {
  const { full, align } = section.styles;
  const alignStyle = styles[`align${align}`];
  const elements = [];
  const cats = [];

  const getCategories = (item, first) => {
    let result = !first ? [item] : [];
    if (item?.children) {
      result = result.concat(item.children.reduce((acc, child) => acc.concat(getCategories(child)), []));
    }

    return result;
  };

  let categories = articleMother?.styles?.categories || [];
  if (section.styles.showSubcategoriesFrom) {
    const category = findCategoryById(categories, section.styles.showSubcategoriesFrom);
    if (category?.children?.length > 0) {
      categories = getCategories(category, true);
    } else categories = [];
  }

  section.data.forEach((item, i) => {
    if (!item.active) return;
    if (['HEADINGS/HEADING-TWO', 'PARAGRAPH/PARAGRAPH'].includes(item.type)) {
      let className = `${styles[`align${item.align || align}`]}`;
      let data;
      if (item.type === 'HEADINGS/HEADING-TWO') {
        className = `${className} ${styles.title}`;
        data = `<h2>${item.text}</h2>`;
      } else {
        className = `${className} ${styles.text}`;
        data = `<div>${item.text}</div>`;
      }

      const result = (
        <div key={`${section._id}_layout_${i}`} className={className}>
          {HTMLParser(data)}
        </div>
      );

      elements.push(result);
    }
  });

  let bgColor = '#000';
  let fontColor = '#fff';
  let iconColor = '#000';
  if (section.styles.appearance === 'light') {
    bgColor = '#fff';
    fontColor = '#000';
    iconColor = '#fff';
  }

  categories?.forEach((c) => {
    if (c) {
      const iconBGColor = formColor({ solid: c.color }, false, 1, null, themeData.colors);
      const cols = `col-12 col-md-${12 / section.styles.columns}`;
      cats.push(
        <div key={`category_card_${c._id}`} className={`${cols} ${styles.col}`}>
          <Link to={categoryPathList[c._id]} draggable="false" className={styles.link}>
            <div className={styles.card} style={{ backgroundColor: bgColor, color: fontColor }}>
              {section.styles.appearance === 'with' && c?.image?.id && (
                <div className={styles.bgImage}>
                  <div className={styles.overlay} />
                  <Image
                    id={c.image.id}
                    images={images}
                    sizes={`(min-width: 768px) ${100 / section.styles.columns}vw, 100vw`}
                    loadingStrategy={isFirst ? 'eager' : undefined}
                  />
                </div>
              )}
              <div className={styles.content}>
                {section.styles.categoryIcon && (c.icon?.icon || c.icon?.id) && (
                  <div style={iconBGColor} className={styles.iconBG}>
                    <div className={styles.icon}>
                      <SectionMedia
                        mediaType={c?.icon?.icon ? 'ICON' : 'IMAGE'}
                        data={{
                          ...c.icon,
                          icon: c?.icon?.icon ? { ...c.icon.icon, size: '32px', color: iconColor } : null,
                        }}
                        colors={themeData.colors}
                        wrapperStyleName="categoryIcon"
                        images={images}
                        sizes="40px"
                        loadingStrategy={isFirst ? 'eager' : undefined}
                      />
                    </div>
                  </div>
                )}
                {section.styles.categoryTitle && c.name && (
                  <div className={styles.cardTitle}>
                    <h3
                      style={{ color: fontColor }}
                      className={styles[`Subtitle${themeData.typography.heading.fontSize}`]}
                    >
                      {HTMLParser(c.name)}
                    </h3>
                  </div>
                )}
                {section.styles.categoryDescription && c.description && (
                  <div className={styles.cardText}>
                    <div style={{ color: fontColor }}>{HTMLParser(c.description)}</div>
                  </div>
                )}
              </div>
            </div>
          </Link>
        </div>,
      );
    }
  });

  return (
    <div className={`container ${full ? styles.containerFull : styles.container}`}>
      {elements}
      {cats.length > 0 && <div className={`row ${alignStyle}`}>{cats}</div>}
    </div>
  );
};

export default CategoryLayout;
