// extracted by mini-css-extract-plugin
export var lbContainerOuter = "x_tq";
export var lbContainerInner = "x_tr";
export var movingForwards = "x_ts";
export var movingForwardsOther = "x_tt";
export var movingBackwards = "x_tv";
export var movingBackwardsOther = "x_tw";
export var lbImage = "x_tx";
export var lbOtherImage = "x_ty";
export var prevButton = "x_tz";
export var nextButton = "x_tB";
export var closing = "x_tC";
export var appear = "x_tD";